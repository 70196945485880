<template>
  <!--
    Displays all contacts related to the installation.
  -->
  <div class="contactList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template
        v-if="contactsAvailable"
        class="defaultTable mt-3"
      >
        <template v-for="(contact, index) in contacts">
          <ContactDetail
            :key="'contact' + index"
            :contact="contact"
            :installation-id="installationId"
            :installation-name="installationName"
            :is-call-duty="isCallDuty"
          />
        </template>
      </template>
      <div
        v-else
        class="p-4"
      >
        {{ $t('noDataAvailable') }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ContactList",
  components: {
    ContactDetail : () => import('@/components/Contact/ContactDetail.vue')
  },
  props: {
    isCallDuty: {
      type: Boolean,
      required: false,
      default: false
    },
    installationId: {
      type: String,
      required: true
    },
    installationName: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      loading: true,
      contacts: null
    }
  },
  computed: {
    contactsAvailable: function () {
      if (this.contacts == null) {
        return false;
      }
      if (this.contacts.length === 0) {
        return false;
      }
      if (this.installationName == null) {
        return false;
      }
      return true;
    }
  },
  created () {
    this.getContacts();
  },
  methods: {
    getContacts () {
      this.loading = true;
      this.axios.get(this.isCallDuty ? '/OnCallDuty/GetInstallationOnCallDutyContacts' : ('/Contact/Contacts?installationId=' + this.installationId))
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.contacts = response.data.filter(x => !x.firstname || (x.firstname && !x.firstname.startsWith('Alturos')));
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>
